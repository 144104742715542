import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { doNothingAnchor } from "../../components/Old2005BlogPage";
import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—November 2009" />
	<NewsHeader />
    <h2>November 2009</h2>



	<h3 className="blogdate">
	Friday, November 13, 2009
	</h3>

	<h4 className="blogitemtitle">
	Come be part of Vorn's "recorded live" concert
	</h4>
		<div className="blogitembody">
		<p>I'm playing an opening set of my tunes at my friend Vorn's concert this
	upcoming Thursday - it's gonna be a cool show because Vorn and band are
	planning to record a live CD from this concert. Come make some noise and
	it'll be a fun night! Vorn's band is one of the first bands I saw in
	Wellington when I arrived in 2006, and I quickly became a fan. I'll
	probably play 30 to 45 minutes and then the evening will turn very
	uniquely Vornesque.</p>

	<p>
	Here are the details:
	</p>
	<ul>
	<li>
	<a href="http://www.facebook.com/event.php?eid=169089123601">Facebook
	event page</a> (invite your friends!)
	</li>
	<li>
	<a href="http://www.myspace.com/vornmusic">Vorn's myspace page</a>
	(hear some music)
	</li>
	<li>
	<b><span>Thursday, November 19</span></b> @ <span><b>9:00 pm</b></span>
	($5 entry) @ <span><b>Happy</b></span>, Cnr of Tory and Vivian
	streets, Wellington, New Zealand
	</li>
	</ul>
	<p>See you soon,</p>

	<p>
	Jesse
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 16:41 +1300</p>
		</div>


	<h3 className="blogdate">
	Friday, November 13, 2009
	</h3>

	<h4 className="blogitemtitle">
	Down Again live at Mojo Invincible last month
	</h4>
		<div className="blogitembody">
		<p>Here's a video of Stu Pedley and I having fun with <span><i>Down Again</i></span>
	at my last show. Thanks to my friend Ana for capturing this with her
	camera; I tried to film with my own camera but I chose a bad angle... ha
	ha, the back of my head! I'll try to get another song or two out to you
	soon from Ana's camera.</p>

	<ul>
	<li>
	<a href="http://www.youtube.com/watch?v=aJwcv2Xz0qU&fmt=18">Down Again
	at Youtube</a> (share with friends!)
	</li>
	<li>
	Also downloadable as a podcast episode (<a href="/" onClick={doNothingAnchor}>(long since removed)</a>
	</li>
	</ul>
	<p>Thanks, and enjoy!</p>

	<p>
	Jesse
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 16:35 +1300</p>
		</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
